import {SPECS as SdkSpecs} from '@wix/wixstores-client-storefront-sdk/dist/es/src/constants';

export const SPECS = {
  ...SdkSpecs,
  CASHIER_EXPRESS_IN_PRODUCT_PAGE: 'specs.stores.CashierExpressInProductPage',
  SUBSCRIPTION_PLAN_USE_TPA_RADIO_BUTTON: 'specs.stores.SubscriptionPlanUseTpaRadioButton',
  URL_CUSTOMIZE: 'specs.stores.EnableUrlCustomizeInProductPage',
  USE_LIGHTBOXES: 'specs.stores.UseLightboxes',
  MIGRATE_VOLATILE_CART_API_TO_GRAPHQL_NODE: 'specs.stores.MigrateVolatileCartApiToGraphqlNode',
  BACK_IN_STOCK_PRODUCT_PAGE_BUTTON: 'specs.stores.BackInStockProductPageButton',
  BACK_IN_STOCK_MULTIPLE_LANGUAGES: 'specs.stores.BackInStockMultiLangs',
  USE_DROPDOWN_TPA_LABEL: 'specs.stores.UseDropdownTpaLabel',
  MANAGE_PRODUCT_BRAND: 'specs.stores.ManageProductBrand',
  USE_CHECKOUT_ID_IN_FAST_FLOW: 'specs.stores.UseCheckoutIdInProductPageFastFlow',
  USE_CHECKOUT_ID_IN_SUBSCRIPTION: 'specs.stores.UseCheckoutIdInSubscription',
  EXPOSE_WISHLIST_BUTTON_WITHOUT_MY_ORDER: 'specs.stores.ExposeWishlistButtonWithoutMyOrderPage',
  PRODUCT_DESCRIPTION_RTL_BULLETS_FIX: 'specs.stores.ProductDescriptionRtlBulletsFix',
  PRODUCT_INFO_SECTION_DESCRIPTION_RTL_BULLETS_FIX: 'specs.stores.ProductInfoSectionDescriptionRtlBulletsFix',
  CREATE_CART_VIA_GRAPHQL_NODE_WITH_IS_MANDATORY: 'specs.stores.CreateCartViaGraphqlNodeWithIsMandatory',
  VIDEO_SEO: 'specs.stores.addVideoDataToProductPageSEO',
  BUY_NOW_WITHOUT_GC: 'specs.stores.BuyNowWithoutGC',
  CASHIER_EXPRESS_ADD_USER_INPUT_TO_PRODUCT_NAME: 'specs.stores.CashierExpressAddUserInputsToProductName',
  SHOW_STOCK_INDICATOR: 'specs.stores.ShowStockIndicatorInProductPage',
  SHOW_OUT_OF_STOCK_INDICATOR_WHEN_BACK_IN_STOCK_ACTIVE: 'specs.stores.ShowOutOfStockIndicatorWhenBackInStockActive',
} as const;

export enum clientSideBuildCheckoutUrlSpecValues {
  Closed = 'closed',
  Compare = 'compare',
  UseNewBuilder = 'useNewBuilder',
}
